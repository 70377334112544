import React, {useEffect} from 'react'
import {Breadcrumb} from '../components/breadcrumb'
import Layout from '../components/layout'
import {ProjectTile} from '../components/projectTile'
import Seo from '../components/seo'

const Projects = () => {
  const infoSection = (
    <div className="container">
      <div className="row animated fadeInUp delay1">
        <div className="col-md-5">
          <h1>Projekte</h1>
        </div>
        <div className="col-md-7">
          <p>Eine Auswahl</p>
        </div>
      </div>
    </div>
  )

  const jsonExplorer = (
    <ProjectTile
      headlineTop={
        <>
          Open Source<span>Flutter Json Explorer</span>
        </>
      }
      textTop="Wir entwickeln eine Flutter Package(Open Source Bibliothek) zur übersichtlichen Darstellung von komplexen JSON-Daten in Flutter-Applikationen. Diese kann sowohl bei der Entwicklung von Apps als auch im produktiven Betrieb eingesetzt werden"
      iconTop="fa-code"
      imgSrc="/img/content/flutter.webp"
      isLightBg={true}
    />
  )
  const kiUmfragen = (
    <ProjectTile
      headlineTop={
        <>
          KI / Machine Learning<span>Eine Umfrage</span>
        </>
      }
      textTop="Für die bessere Auswertung von Umfragen bauen wir ein KI gestütztes Tool. Leider können wir zum aktuellen Zeitpunkt an dieser Stelle nicht weiter ins Detail gehen."
      iconTop="fa-calculator"
      imgSrc="/img/content/ki.webp"
      fadeIn="left"
    />
  )
  const partyGame = (
    <ProjectTile
      headlineTop={
        <>
          Gaming?<span>Zusammen!</span>
        </>
      }
      textTop="Um unseren spieltrieb freien Lauf zu lassen, stellen wir eine Cross-Device Gaming-App her. Diese Multi-User Anwendung wird sowohl auf Smart-Fernseher und im Browser als auch als App auf mobilen Geräten laufen. So können alle Teilnehmer über ihr Gerät am Spiel teilnehmen und den gemeinsamen Fortschritt am großen Bildschrim betrachten."
      iconTop="fa-gamepad"
      imgSrc="/img/content/game.webp"
      isLightBg={true}
    />
  )

  return (
    <Layout infoSection={infoSection}>
      <Seo title="Projekte" />
      <Breadcrumb crumbs={[{label: 'Projekte'}]} />
      {jsonExplorer}
      {kiUmfragen}
      {partyGame}
    </Layout>
  )
}

export default Projects
