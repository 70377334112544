import React from 'react'

export interface ProjectTileProps {
  headlineTop: React.ReactNode
  textTop: string
  iconTop: string
  headlineButtom?: React.ReactNode
  textButtom?: string
  iconButtom?: string
  imgSrc: string
  fadeIn?: 'left' | 'right'
  isLightBg?: boolean
}

export const ProjectTile = ({
  headlineTop,
  textTop,
  iconTop,
  headlineButtom,
  textButtom,
  iconButtom,
  imgSrc,
  fadeIn = 'right',
  isLightBg = false,
}: ProjectTileProps) => {
  const image = (
    <div
      className={`col-md-6 col-xs-12 wow ${
        fadeIn === 'right' ? 'fadeInLeft' : 'fadeInRight'
      } animated`}
    >
      <img src={imgSrc} alt="" className="img-responsive" />
    </div>
  )

  return (
    <section className="content_info">
      <div
        className={`${isLightBg ? 'padding_bottom' : 'info_resalt borders'}`}
      >
        <div className="container">
          <div
            className="row"
            style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
          >
            {fadeIn === 'left' ? image : null}
            <div
              className={`col-md-6 col-xs-12 wow ${
                fadeIn === 'right' ? 'fadeInRight' : 'fadeInLeft'
              } animated`}
            >
              <div className="col-md-12">
                <div className="service-02">
                  <div className="head-service-02">
                    <i className={`fa ${iconTop}`}></i>
                    <h3>{headlineTop}</h3>
                  </div>
                  <div className="caption-service-02">
                    <p>{textTop}</p>
                  </div>
                </div>
              </div>

              {headlineButtom && (
                <div className="col-md-12">
                  <div className="service-02">
                    <div className="head-service-02">
                      <i className={`fa ${iconButtom}`}></i>
                      <h3>{headlineButtom}</h3>
                    </div>
                    <div className="caption-service-02">
                      <p>{textButtom}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {fadeIn === 'right' ? image : null}
          </div>
        </div>
      </div>
    </section>
  )
}
